import React from 'react';

export const Footer = () => {
  return (
    <div className="footer">
      Powerded by <a className='text-white fw-bold' target="_blanc" href="https://www.vippayperhead.com/">
        Vip Pay Per Head
      </a>
    </div>
  )
}
